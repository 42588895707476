
<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer fluid v-if="ventana == 1">
      <CRow class="justify-content-center">
        <CCol class="col-10">
          <CCardGroup>
            <CCard class="mx-4 mb-0">
              <CCardBody class="p-4">
                <CForm validate @submit="generarCodigosActivacion">
                  <h1>Finaliza tu suscripción</h1>
                  <p class="text-muted">
                    Finaliza tu suscripción para no recibir mensajes de texto de
                    nuestra plataforma en tu número de celular
                  </p>
                  <template>
                    <CAlert
                      :show.sync="dismissCountDown"
                      closeButton
                      color="danger"
                      fade
                    >
                      <span v-html="mensajeError"></span>
                      <strong
                        >Borrando Mensaje en {{ dismissCountDown }}</strong
                      >
                      segundos...
                    </CAlert>
                  </template>
                  <CInput
                    label="Celular (Con Indicativo del Pais Ej: 573000000000)"
                    autocomplete="celular"
                    @keypress="isNumber($event)"
                    minlength="10"
                    maxlength="12"
                    required
                    was-validated
                    v-model="form.celular"
                  >
                  </CInput>
                  <div class="form-group">
                    <label for="">Motivo del Bloqueo</label>
                    <textarea
                      class="form-control"
                      placeholder="Ingresa aqui el motivo por el cual quieres bloquear tu número para no recibir mensajes de texto"
                      v-model="form.motivo"
                      cols="5"
                      rows="3"
                      minlength="3"
                      required
                      was-validated
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <div>
                      <div id="captcha"></div>
                      <CRow>
                        <CCol col="5">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Captcha"
                            id="cpatchaTextBox"
                            size="sm"
                            v-model="cpatchaTextBox"
                          />
                        </CCol>
                        <CCol col="1">
                          <CButton
                            color="light"
                            @click="createCaptcha"
                            title="Cambiar Captcha"
                            ><CIcon name="cil-history"
                          /></CButton>
                        </CCol>
                      </CRow>
                    </div>
                  </div>
                  <hr />
                  <CButton color="success" type="submit" block
                    >Bloquear Número</CButton
                  >
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="dark"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h3>Bloquea Tu Número</h3>
                <!--<div v-if="$can('edit posts')">You can edit posts.</div>-->
                <p class="text-justify">
                  Finaliza tu suscripción para no recibir mensajes de texto de
                  nuestra plataforma en tu número de celular
                </p>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
    <CContainer fluid v-if="ventana == 2">
      <CRow class="justify-content-center">
        <CCol class="col-10">
          <CCardGroup>
            <CCard class="mx-4 mb-0">
              <CCardBody class="p-4">
                <CForm validate @submit="enviarCodigos">
                  <h3>Código Activación Bloqueo</h3>
                  <template>
                    <CAlert
                      :show.sync="dismissCountDown"
                      closeButton
                      color="danger"
                      fade
                    >
                      <span v-html="mensajeError"></span>
                      <strong
                        >Borrando Mensaje en {{ dismissCountDown }}</strong
                      >
                      segundos...
                    </CAlert>
                  </template>
                  <br />
                  <CInput
                    label="Código Enviado Por Celular"
                    autocomplete="off"
                    minlength="3"
                    maxlength="6"
                    v-model="form_codigos.codigo_celular"
                    required
                    was-validated
                  >
                  </CInput>
                  <hr />
                  <CButton color="success" type="submit" block
                    >Bloquear Número
                  </CButton>

                  <hr />
                  <h6>
                    Si escribiste incorrectamente el número celular, haz
                    <a @click="modificarFormulario" class="text-primary"
                      >Click Aqui</a
                    >
                    y registrate nuevamente
                  </h6>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="info"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h5 class="text-justify">
                  Hemos enviado un mensaje con el código de activación de
                  bloqueo de número de teléfono
                </h5>
                <p class="text-justify">
                  <strong>Número Celular:</strong> {{ this.form.pais
                  }}{{ this.form.celular }}
                </p>
                <h6 class="text-justify">
                  Ingresalos a continuación para activar el bloqueo
                </h6>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
    <CContainer fluid v-if="ventana == 3">
      <CRow class="justify-content-center">
        <CCol class="col-10">
          <CCardGroup>
            <CCard
              class="mx-4 mb-0 text-center py-5"
              color="success"
              text-color="white"
            >
              <CCardBody class="p-4">
                <h1>Número Bloqueado Con Exito</h1>
                <br />
                <h4>
                  Desde este momento ya no recibiras mas mensajes de texto de
                  nuestra plataforma
                </h4>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
    <div
      :style="
        'width: 100%;height: 100%;top: 0;left: 0;position: fixed;display: flex;opacity: 0.3;background-color: #fff;z-index: 10000;text-align: center;justify-content: center;align-items: center;visibility:' +
        visibleLoading
      "
    >
      <CSpinner style="width: 8rem; height: 8rem" color="info" grow />
    </div>
  </div>
</template>

<script>
export default {
  name: "BloqueoNumeros",
  data() {
    return {
      errors: [],
      errorCodigos: [],
      ventana: 1,
      form: {
        celular: "",
        motivo: "",
      },
      form_codigos: {
        codigo_celular: "",
      },
      mensajeError: "",
      dismissCountDown: 0,
      code: null,
      cpatchaTextBox: "",
    };
  },
  computed: {
    isDisabled: function () {
      return !this.form.condiciones;
    },
    visibleLoading() {
      return this.$store.state.auth.loading_http;
    },
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        //if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    generarCodigosActivacion: function (e) {
      e.preventDefault();
      let valida_capt = this.validateCaptcha();
      if (valida_capt == "Error") {
        return true;
      }
      //this.ventana = 2;
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .post("/sms/bloqueanumero/regitrar", {
          celular_bloquear: this.form.celular,
          motivo: this.form.motivo,
        })
        .then((response) => {
          if (response.data.status == "OK") {
            this.ventana = 2;
          } else {
            let celular = response.data.celular;
            let mensajeError = "";
            if (celular != undefined) {
              mensajeError += `* ${celular} <br>`;
            }
            if (
              response.data.status == "ERROR1" ||
              response.data.status == "ERROR2"
            ) {
              mensajeError = response.data.mensaje_celular + " <br>";
            }

            if (mensajeError == "") {
              mensajeError =
                "Ocurrio Un Error En Nuestra Plataforma Por Favor Intentar Mas Tarde <br>";
            }

            this.mensajeError = mensajeError;
            this.dismissCountDown = 60;
          }
        })
        .catch((error) => {
          let mensajeError = "";
          if (error.response) {
            let dataError = error.response.data;
            if (error.response.status == 422) {
              mensajeError = `${dataError.message} \n`;
              let errors = dataError.errors;
              for (const key in errors) {
                if (Object.hasOwnProperty.call(errors, key)) {
                  const element = errors[key];
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`;
                  }
                }
              }
              this.mensajeError = mensajeError;
              this.dismissCountDown = 60;
            } else {
              this.mensajeError =
                "Ocurrio Un Error En Nuestra Plataforma Por Favor Intentar Mas Tarde <br>";
              this.dismissCountDown = 60;
            }
          }
        })
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    modificarFormulario(e) {
      this.ventana = 1;
      this.form = this.$store.state.crearcuentasms.formularioRegistrar;
      this.form_codigos.codigo_celular = "";
      e.preventDefault();
      this.createCaptcha();
    },
    enviarCodigos(e) {
      e.preventDefault();
      //this.ventana = 2;
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .post("/sms/bloqueanumero/validar", {
          celular: this.form.celular,
          motivo: this.form.motivo,
          codigo_celular: this.form_codigos.codigo_celular,
        })
        .then((response) => {
          if (response.data.status == "OK") {
            this.ventana = 3;
          } else if (response.data.status == "ERROR") {
            let mensajeError = "";
            if (response.data.mensaje_codigo_celular == "ERROR") {
              mensajeError += `* El Código Enviado Por El Celular Es Incorrecto <br>`;
            }
            if (mensajeError == "") {
              mensajeError =
                "Ocurrio Un Error En Nuestra Plataforma Por Favor Intentar Mas Tarde <br>";
            }
            this.mensajeError = mensajeError;
            this.dismissCountDown = 60;
          }
        })
        .catch(() => {
          this.mensajeError =
            "Ocurrio Un Error En Nuestra Plataforma Por Favor Intentar Mas Tarde <br>";
          this.dismissCountDown = 60;
        })
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    calcTime(city, offset) {
      // creamos el objeto Date (la selecciona de la máquina cliente)
      let d = new Date();
      // lo convierte  a milisegundos
      // añade la dirferencia horaria
      // recupera la hora en formato UTC
      let utc = d.getTime() + d.getTimezoneOffset() * 60000;
      // crea un nuevo objeto Date usando la diferencia dada.
      let nd = new Date(utc + 3600000 * offset);
      try {
        let hora = nd.toTimeString().substr(0, 8);
        let fecha = nd.toLocaleString().split(" ")[0];
        fecha = fecha.replace(",", "");
        let tipo = "a. m.";
        //console.log(fecha);
        if (
          parseInt(hora.split(":")[0]) >= 12 &&
          parseInt(hora.split(":")[0]) <= 23
        ) {
          tipo = "p. m.";
        }
        let nueva_fecha = `${fecha}, ${hora} ${tipo}`;
        //console.log(nd.toLocaleString());
        return nueva_fecha;
      } catch (error) {
        return nd.toLocaleString();
      }
    },
    validarFueraDeHorario() {
      let fechaColombia = this.calcTime("Bogota", "-5");
      //console.log(fechaColombia);
      //let fechaActual = new Date().toLocaleString();
      //let horaActual = fechaActual.split(" ")[1];
      let horaColombia = fechaColombia.split(" ")[1];
      //let tiempoActual = fechaActual.split(" ")[2];
      let tiempoColombia = fechaColombia.split(" ")[2];
      horaColombia = horaColombia.trim();
      //horaColombia = '3:59:59'
      //tiempoColombia = 'p. m.'
      horaColombia = this.convertirHora24X12(horaColombia);
      //console.log(horaColombia);
      //let calcula = horaColombia.split(":")[0];
      //console.log(calcula);
      //horaColombia = calcula >= 1 && calcula <= 9 ? '0'+horaColombia : horaColombia;
      //console.log(horaColombia);
      //horaColombia = '14:10:59'
      //tiempoColombia = 'a. m.'
      horaColombia = horaColombia.substr(0, 5);
      //console.log(horaColombia);
      //console.log(tiempoColombia);
      if (
        (horaColombia >= "07:00" &&
          horaColombia <= "11:59" &&
          tiempoColombia == "a. m.") ||
        (horaColombia >= "12:00" &&
          horaColombia <= "12:59" &&
          tiempoColombia == "p. m.") ||
        (horaColombia >= "01:00" &&
          horaColombia <= "06:59" &&
          tiempoColombia == "p. m.")
      ) {
        this.ventana = 1;
        return "OK";
      } else {
        this.ventana = 1; //this.ventana = 3;
        return "OK";
      }
    },
    convertirHora24X12(hora) {
      let calcula = hora.split(":")[0];
      if (calcula >= 13 && calcula <= 23) {
        calcula = calcula - 12;
        let horamod = hora.split(":");
        horamod[0] = calcula;
        hora = horamod.join(":");
      }

      return hora;
    },
    createCaptcha() {
      //clear the contents of captcha div first
      document.getElementById("captcha").innerHTML = "";
      var charsArray =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%&*";
      var lengthOtp = 6;
      var captcha = [];
      for (var i = 0; i < lengthOtp; i++) {
        //below code will not allow Repetition of Characters
        var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
        if (captcha.indexOf(charsArray[index]) == -1)
          captcha.push(charsArray[index]);
        else i--;
      }
      var canv = document.createElement("canvas");
      canv.id = "captcha";
      canv.width = 120;
      canv.height = 50;
      var ctx = canv.getContext("2d");
      ctx.font = "25px Georgia";
      ctx.strokeText(captcha.join(""), 0, 30);
      //storing captcha so that can validate you can save it somewhere else according to your specific requirements
      this.code = captcha.join("");
      document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element
    },
    validateCaptcha() {
      if (this.cpatchaTextBox == this.code) {
        this.dismissCountDown = 0;
        return "OK";
      } else {
        this.createCaptcha();
        this.mensajeError = "Captcha incorrecto digitar nuevamente";
        this.dismissCountDown = 20;
        return "Error";
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.ventana = 1;
      this.createCaptcha();
    }, 100);
  },
};
</script>

<style>
/*input[type="text"] {
  padding: 12px 20px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 12px 30px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
}*/
canvas {
  /*prevent interaction with the canvas*/
  pointer-events: none;
}
</style>

